import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatMoney } from '../../../util/currency';
import { ensureListing } from '../../../util/data';

import css from './SearchMapPriceLabel.module.css';

const canonicalRootURL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;

const MapMarker = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="150"
      fill="none"
      viewBox="0 0 150 150"
    >
      <path
        fill="#45A8D4"
        stroke="#fff"
        strokeWidth="3"
        d="M75.525 147.517c-9.675-8.63-23.674-22.774-35.393-38.211-6.057-7.979-11.48-16.272-15.385-24.301C20.835 76.959 18.5 69.28 18.5 62.5c0-34.745 25.9-61 57-61 31.13 0 56.5 23.287 56.5 61 0 14.871-9.387 32.236-21.444 48.079-11.599 15.243-25.452 28.801-35.031 36.938z"
      ></path>
      <path
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M101.207 63.96c-.256-.61-.86-1.004-1.495-1.217L75.72 53.197a3.049 3.049 0 00-1.816 0l-23.969 9.551c-.64.2-1.26.612-1.513 1.222-.254.61-.377 1.201-.129 1.933l5.964 15.272a.955.955 0 00.958.594c3.42-.215 6.448-1.952 8.764-4.165a.982.982 0 011.345 0c2.476 2.371 5.772 4.165 9.489 4.165 3.717 0 6.993-1.794 9.469-4.17a.983.983 0 011.346 0c2.316 2.215 5.343 3.955 8.764 4.17a.954.954 0 00.957-.59l5.985-15.273c.254-.613.127-1.335-.127-1.946z"
      ></path>
      <path
        fill="#fff"
        d="M95.32 91.144a.876.876 0 00-.456-.769c-3.47-1.865-6.635-4.72-8.027-6.152a1.288 1.288 0 00-1.63-.194c-6.452 4.156-14.306 4.158-20.758.007a1.294 1.294 0 00-1.644.2c-1.38 1.445-4.51 4.268-8.002 6.12a.917.917 0 00-.498.734.863.863 0 001.015.917c2.672-.536 5.255-1.754 7.716-3.055a1.116 1.116 0 011.025-.007 23.73 23.73 0 0021.518.002 1.13 1.13 0 011.037.008c2.448 1.282 5.027 2.511 7.69 3.05a.861.861 0 001.02-.86h-.005z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M83.016 42.805v-3.077a3.085 3.085 0 00-3.077-3.076H69.686a3.086 3.086 0 00-3.077 3.076v3.077m28.711 17.56V48.956a6.17 6.17 0 00-6.152-6.152H60.457a6.17 6.17 0 00-6.152 6.152v11.792m20.508-6.716v27.281"
      ></path>
    </svg>
  )
}

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(isSameListing && hasSamePrice && hasSameActiveStatus && hasSameRefreshToken);
  }

  render() {
    const {
      className,
      rootClassName,
      intl,
      listing,
      onListingClicked,
      isActive,
      config,
    } = this.props;
    const currentListing = ensureListing(listing);
    const { price } = currentListing.attributes;

    // Create formatted price if currency is known or alternatively show just the unknown currency.
    const formattedPrice =
      price && price.currency === config.currency ? formatMoney(intl, price) : price.currency;

    const classes = classNames(rootClassName || css.root, className);
    const priceLabelClasses = classNames(css.priceLabel, { [css.priceLabelActive]: isActive });
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });

    return (
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        {/* <div className={css.caretShadow} /> */}
        <div className={priceLabelClasses}>
          <MapMarker />
        </div>
        {/* <div className={caretClasses} /> */}
      </button>
    );
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,
  config: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);
